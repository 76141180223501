import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, A } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)``;

const ContentWidget = styled(Box)`
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  background-color: ${({ theme }) => theme.colors.light};
  padding: 20px;
  min-width: 100%;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;

  @media ${device.xl} {
    width: 360px;
    min-width: 360px;
  }
  @media ${device.md} {
    min-height: 100%;
  }
`;

const ContentWidgetIcon = styled(Box)`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const ContentCard = ({ children = "" }) => {
  return (
    <ContentWidget>
      <ContentWidgetIcon>
        <i className="fas fa-check"></i>
      </ContentWidgetIcon>
      <div className="d-flex align-items-center">
        <Title variant="card" mb={0}>
          {children}
        </Title>
      </div>
    </ContentWidget>
  );
};

const Content = () => {
  return (
    <>
      {/* <!-- Content section --> */}
      <SectionStyled bg="#f2f2f3">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg="9">
              <div className="text-center">
                <Title>Find Your Industry:</Title>
                <Text>
                  Regardless of size, our optimizations are diverse and flexible
                  to accommodate any industry, including:
                </Text>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/cmms-software-solutions">
                {" "}
                <ContentCard>CMMS Software</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/cafm-software">
                <ContentCard>CAFM Software</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/facility-management-software">
                <ContentCard>Facility Management Software</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/manufacturing-solutions">
                <ContentCard>Manufacturing Solutions</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/prop-management-solutions">
                <ContentCard>Property Management Solutions</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/health-solutions">
                <ContentCard>Healthcare Solutions</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/school-solutions">
                <ContentCard>School Software Solutions</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/restaurant-solutions">
                <ContentCard>Restaurant Solutions</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/public-sector-solutions">
                <ContentCard>Government Solutions</ContentCard>
              </A>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A href="/non-profit-solutions">
                <ContentCard>Non-Profit Solutions</ContentCard>
              </A>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content;
