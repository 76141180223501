import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button, A } from "../../components/Core";
import { device } from "../../utils";

import imgCtaBG from "../../assets/image/jpeg/l2-cta-big.jpg";
import imgOval from "../../assets/image/png/l2-cta-oval.png";

const ImageBottom = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: 21%;
  right: 0;
  z-index: 2;
  border-radius: 500px;
  max-width: 50%;
  overflow: hidden;

  @media ${device.md} {
    max-width: 100%;
    right: 15%;
  }
`;

const Conts = () => (
  <>
    <Section bg="ash">
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={10} className="">
            <div className="position-relative mb-5">
              <div
                className="img-1"
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img
                  src={imgCtaBG}
                  alt=""
                  className="img-fluid"
                  css={`
                    border-radius: 10px;
                  `}
                />
              </div>
              <ImageBottom
                className=""
                bg="light"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <img src={imgOval} alt="" className="img-fluid" />
              </ImageBottom>
            </div>
          </Col>
          <Col
            lg={6}
            md={9}
            className=""
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
          >
            <div className="">
              <Title color="light">Can’t find your industry?</Title>
              <Text color="light" opacity={0.7} mb={4}>
                feel free to send us an email and we’ll be happy to give you an
                even deeper lowdown!
              </Text>
            </div>
            <div>
              <A href="/contact">
                {" "}
                <Button>Contact Us</Button>
              </A>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Conts;
