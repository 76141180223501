import React from "react";

import PageWrapper from "../components/PageWrapper";
import Content from "../sections/all-stars-sec/Content";
import Hero from "../sections/all-stars-sec/Hero";
import Conts1 from "../sections/all-stars-sec/Conts1";

const allOffers = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero />
        <Content />
        <Conts1 />
      </PageWrapper>
    </>
  );
};
export default allOffers;
